import React from "react"

import styled from "styled-components"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import {
  Container,
  PageTitle,
  PostText,
  mobileWidth,
  desktopWidth
} from "../utils/common-styles"
import ogImage from "../images/image_rekryterautvecklare.png"
import VictorBjorklund from '../images/victor_bjorklund.png'

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${mobileWidth}px) {
    flex-direction: column;
    justify-content: stretch;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 65%;
  @media (max-width: ${desktopWidth}px) {
    margin-bottom: 30px;
    width: 100%;
  }
  &:last-child {
    margin-right: 0;
  }
`


const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 35%;
  align-items: flex-end;
  @media (max-width: ${desktopWidth}px) {
    margin-bottom: 30px;
    display: none
  }
  &:last-child {
    margin-right: 0;
  }
`






const AboutPage = ({ path }) => (
  <LayoutPage
    path={path}
    title="Om Rekryterautvecklare.se"
  >
    <SEO title="Om oss" description="Om oss" image={ogImage} />
    <Container>
      <Columns>
 
        <LeftColumn>
          {/* <PageTitle>Some about page details fe fedferwf fefgwegrwgw</PageTitle> */}
          <PostText>
        <strong>Hej!</strong><br/><br/> <p>Jag skapade sidan för att jag såg ett behov av information om var man kan hitta duktiga utvecklare. Jag har märkt att det inte finns mycket information som på ett lätt sätt förklarar hur och var man hitta duktiga utvecklare. Det här är såklart inte en komplett information om hur man hitta utvecklare men det är mitt försök till att ge lättsmält information på ett interaktivt sätt. Jag har både en rad artiklar om vad man ska tänka på när man letar utvecklare (du hittar dem på bloggdelen) samt sidans huvudfokus vilket är ett interaktivt quiz där du kan svara på ett antal frågor.</p>

         <p>Vill du bidra till sidan? Då når du mig via <a href="/contact">kontaktsidan</a>.</p>

         <p><strong>/Victor Björklund</strong></p>
          </PostText>
          </LeftColumn>


        <RightColumn><img src={VictorBjorklund} alt="Victor Björklund" /></RightColumn>
       
      </Columns>
    </Container>
  </LayoutPage>
)

export default AboutPage
